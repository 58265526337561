:root {
    /* Palette */
    --dark-green: #042a2b;
    --blue-munsell: #62929e;
    --mint-cream: #f1f7ed;
    --bittersweet: #ee6352;
    --coral: #fb8f67;

    /* Utility */
    --white: #fff;
    --blue-munsell-light: #76aebc;

    /* Bins */
    --bin-black: #333;
    --bin-blue: rgb(85, 85, 201);
    --bin-green: rgb(70, 132, 70);
}

.app-wrapper {
    background-color: var(--dark-green);
    color: var(--mint-cream);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    padding: 1em;
}

h1 {
    margin-top: 0.33em;
    margin-bottom: 0.33em;
}

a {
    color: var(--blue-munsell);
}

input[type=text] {
    background-color: var(--dark-green);
    border: 1px solid var(--blue-munsell);
    border-radius: 5px;
    color: var(--mint-cream);
    font-size: 0.9em;
    width: 12ch;
}

.bin-form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.bin-form label {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.bin-form label input {
    margin-left: 0.5em;
}

.bin-form button {
    margin-top: 20px;
}

.standard-button {
    border: none;
    font-size: 1em;
    border-radius: 8px;
    padding: 0.25em 1em;
}

.standard-button:hover {
    cursor: pointer;
}

.standard-button:active {
    cursor: pointer;
}

.standard-button.primary {
    background-color: var(--bittersweet);
    color: var(--white);
}

.standard-button.primary:hover {
    background-color: var(--coral);
}

.standard-button.primary:active {
    background-color: var(--bittersweet);
}

.standard-button.secondary {
    background-color: var(--blue-munsell);
    color: var(--white);
    font-size: 18px;
    margin-top: 1em;
    margin-right: 1em;
}

.standard-button.secondary:hover {
    background-color: var(--blue-munsell-light);
}

.standard-button.secondary:active {
    background-color: var(--blue-munsell);
}
